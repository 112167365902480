interface SvgProp {
  className?: string
}

export const SVGCheckedMark: React.FC<SvgProp> = ({ className = '' }) => {
  return (
    <>
      <svg
        className={className}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0002 5.3335C13.8905 5.3335 11.8282 5.95909 10.0741 7.13115C8.31996 8.30322 6.95279 9.96913 6.14545 11.9182C5.33812 13.8673 5.12688 16.012 5.53846 18.0811C5.95003 20.1503 6.96594 22.0509 8.4577 23.5426C9.94946 25.0344 11.8501 26.0503 13.9192 26.4619C15.9883 26.8734 18.133 26.6622 20.0821 25.8549C22.0312 25.0475 23.6971 23.6804 24.8692 21.9262C26.0412 20.1721 26.6668 18.1098 26.6668 16.0002C26.6668 13.1712 25.543 10.4581 23.5426 8.45769C21.5423 6.4573 18.8291 5.3335 16.0002 5.3335V5.3335ZM16.0002 24.8891C14.2421 24.8891 12.5235 24.3677 11.0618 23.391C9.6 22.4143 8.46068 21.026 7.78791 19.4018C7.11513 17.7776 6.9391 15.9903 7.28208 14.266C7.62506 12.5418 8.47164 10.9579 9.71477 9.71477C10.9579 8.47164 12.5418 7.62505 14.266 7.28207C15.9903 6.93909 17.7776 7.11512 19.4018 7.7879C21.026 8.46068 22.4143 9.59999 23.391 11.0618C24.3677 12.5235 24.8891 14.2421 24.8891 16.0002C24.8891 17.1675 24.6591 18.3233 24.2124 19.4018C23.7657 20.4802 23.111 21.4601 22.2856 22.2856C21.4602 23.111 20.4802 23.7657 19.4018 24.2124C18.3233 24.6591 17.1675 24.8891 16.0002 24.8891V24.8891Z"
          fill="#0B92F1"
        />
        <path
          d="M14.5244 21.1021L10.08 16.6576C9.93433 16.4876 9.85823 16.2688 9.86687 16.0451C9.87551 15.8214 9.96826 15.6092 10.1266 15.4509C10.2849 15.2926 10.4971 15.1998 10.7208 15.1912C10.9445 15.1826 11.1632 15.2587 11.3333 15.4043L14.5244 18.5954L20.5511 12.5687C20.7211 12.4231 20.9398 12.347 21.1636 12.3556C21.3873 12.3643 21.5995 12.457 21.7578 12.6153C21.9161 12.7736 22.0088 12.9858 22.0175 13.2096C22.0261 13.4333 21.95 13.652 21.8044 13.8221L14.5244 21.1021Z"
          fill="#0B92F1"
        />
      </svg>
    </>
  )
}
