interface SvgProp {
  className?: string
  onClick?: () => any
}

export const SVGLogoError: React.FC<SvgProp> = ({ className = '', onClick }) => {
  return (
    <>
      <svg
        onClick={() => (onClick ? onClick() : null)}
        className={className}
        width="164"
        height="164"
        viewBox="0 0 164 164"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip3)">
          <path
            d="M104.718 126.039H58.0846C36.4661 126.039 18.9785 108.596 18.9785 87.0305V51.5012C18.9785 29.9364 36.4661 12.4922 58.0846 12.4922H104.718C126.291 12.4922 143.824 29.9364 143.824 51.5012V87.0305C143.824 108.596 126.337 126.039 104.718 126.039Z"
            fill="#33A9FF"
          />
          <path
            d="M77.2709 110.472L69.009 144.719C67.8612 149.573 73.1858 153.373 77.4083 150.671L125.465 120.087L77.2709 110.472Z"
            fill="#33A9FF"
          />
          <rect
            x="42.3384"
            y="46.9819"
            width="6.89705"
            height="28.7377"
            transform="rotate(-45 42.3384 46.9819)"
            fill="white"
          />
          <rect
            x="47.9829"
            y="67.3032"
            width="6.89705"
            height="28.7377"
            transform="rotate(-135 47.9829 67.3032)"
            fill="white"
          />
          <rect
            x="95.2153"
            y="46.9824"
            width="6.89705"
            height="28.7377"
            transform="rotate(-45 95.2153 46.9824)"
            fill="white"
          />
          <rect
            x="100.86"
            y="67.3032"
            width="6.89705"
            height="28.7377"
            transform="rotate(-135 100.86 67.3032)"
            fill="white"
          />
          <rect x="56.8408" y="81.1299" width="51.7279" height="6" fill="white" />
          <mask id="path-8-inside-1" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M108.569 81.1294H85.5785V100.95C85.5783 100.975 85.5781 101 85.5781 101.025C85.5781 105.908 90.7246 109.867 97.0732 109.867C103.422 109.867 108.568 105.908 108.568 101.025H108.569V81.1294Z"
            />
          </mask>
          <path
            d="M85.5785 81.1294V75.1294H79.5785V81.1294H85.5785ZM108.569 81.1294H114.569V75.1294H108.569V81.1294ZM85.5785 100.95L91.5782 101.014L91.5785 100.982V100.95H85.5785ZM108.568 101.025V95.0248H102.569L102.568 101.025L108.568 101.025ZM108.569 101.025V107.025H114.569V101.025H108.569ZM85.5785 87.1294H108.569V75.1294H85.5785V87.1294ZM91.5785 100.95V81.1294H79.5785V100.95H91.5785ZM91.5781 101.025C91.5781 101.021 91.5781 101.018 91.5782 101.014L79.5789 100.887C79.5784 100.933 79.5781 100.979 79.5781 101.025H91.5781ZM97.0732 103.867C95.1077 103.867 93.5473 103.248 92.6032 102.521C91.6657 101.8 91.5781 101.219 91.5781 101.025H79.5781C79.5781 105.713 82.0638 109.554 85.2867 112.033C88.503 114.507 92.6902 115.867 97.0732 115.867V103.867ZM102.568 101.025C102.568 101.219 102.481 101.8 101.543 102.521C100.599 103.248 99.0387 103.867 97.0732 103.867V115.867C101.456 115.867 105.643 114.507 108.86 112.033C112.082 109.554 114.568 105.714 114.568 101.025L102.568 101.025ZM108.569 95.0248H108.568V107.025H108.569V95.0248ZM102.569 81.1294V101.025H114.569V81.1294H102.569Z"
            fill="white"
            mask="url(#path-8-inside-1)"
          />
        </g>
        <defs>
          <clipPath id="clip3">
            <rect
              width="126.123"
              height="139.096"
              fill="white"
              transform="translate(18.9785 12.4922)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
