import React from 'react'

interface SvgProp {
  className?: string
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => any
}

export const SVGSendMsg: React.FC<SvgProp> = ({ ...props }) => {
  return (
    <>
      <svg
        id = "update-ticket-discussion"
        className={`${props.className}`}
        onClick={props.onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.94599 9.31504C1.42399 9.14104 1.41899 8.86004 1.95599 8.68104L21.043 2.31904C21.572 2.14304 21.875 2.43904 21.727 2.95704L16.273 22.043C16.123 22.572 15.818 22.59 15.594 22.088L12 14L18 6.00004L9.99999 12L1.94599 9.31504Z"
          fill="#0B92F1"
        />
      </svg>
    </>
  )
}
