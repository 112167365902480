interface SvgProp {
  className?: string
}

export const SVGLoginOrder: React.FC<SvgProp> = ({ className = '' }) => {
  return (
    <>
      <svg
        className={className}
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12.0844V67.8601C0 69.5288 1.35254 70.882 3.02185 70.882H56.322C57.9907 70.882 59.3439 69.5288 59.3439 67.8601V12.0844C59.3439 10.4156 57.9907 9.0625 56.322 9.0625H3.02185C1.35254 9.0625 0 10.4156 0 12.0844H0Z"
          fill="#F9AA4B"
        />
        <path
          d="M56.3219 9.0625H52.459C54.1277 9.0625 55.4802 10.4156 55.4802 12.0844V67.8601C55.4802 69.5288 54.1277 70.882 52.459 70.882H56.3219C57.9906 70.882 59.3438 69.5288 59.3438 67.8601V12.0844C59.3438 10.4156 57.9906 9.0625 56.3219 9.0625V9.0625Z"
          fill="#C1762B"
        />
        <path
          d="M37.0337 27.3181L33.8049 24.8889C33.6902 24.8022 33.5321 24.8022 33.4174 24.8889L29.8676 27.5604C29.7528 27.6465 29.5947 27.6465 29.4806 27.5604L25.9277 24.8883C25.813 24.8022 25.6555 24.8022 25.5408 24.8883L22.3096 27.3181C22.0978 27.478 21.7944 27.3267 21.7944 27.0612V9.0625H37.5494V27.0605C37.5494 27.3267 37.2461 27.478 37.0337 27.3181Z"
          fill="#FFEB96"
        />
        <path
          d="M7.06909 49.7699V61.5289C7.06909 62.4634 7.82654 63.2208 8.76099 63.2208H26.7548C27.6892 63.2208 28.4467 62.4634 28.4467 61.5289V49.7699C28.4467 48.8361 27.6892 48.0786 26.7548 48.0786H8.76099C7.82654 48.0786 7.06909 48.8361 7.06909 49.7699Z"
          fill="#F0F0F2"
        />
        <path
          d="M16.0225 54.2834H12.5508C11.9038 54.2834 11.3789 53.7585 11.3789 53.1116C11.3789 52.464 11.9038 51.9397 12.5508 51.9397H16.0219C16.6694 51.9397 17.1937 52.464 17.1937 53.1116C17.1937 53.7585 16.6694 54.2834 16.0225 54.2834Z"
          fill="#575B7A"
        />
        <path
          d="M22.9652 59.3599H12.5508C11.9038 59.3599 11.3789 58.8356 11.3789 58.188C11.3789 57.541 11.9038 57.0161 12.5508 57.0161H22.9652C23.6122 57.0161 24.1371 57.541 24.1371 58.188C24.1371 58.8356 23.6122 59.3599 22.9652 59.3599Z"
          fill="#575B7A"
        />
        <path
          d="M76.8547 20.2046H46.0436C44.3065 20.2046 42.8984 21.6127 42.8984 23.3497V44.3281C42.8984 46.0652 44.3065 47.4733 46.0436 47.4733H47.5212C48.2378 47.4733 48.8195 48.0543 48.8195 48.7715V53.3009C48.8195 53.8832 49.5592 54.1322 49.9114 53.6683L54.2266 47.9866C54.4719 47.6631 54.8546 47.4733 55.2605 47.4733H76.8547C78.5918 47.4733 79.9999 46.0652 79.9999 44.3281V23.3497C79.9999 21.6127 78.5918 20.2046 76.8547 20.2046Z"
          fill="#DEF6FF"
        />
        <path
          d="M52.1538 40.9133C51.5068 40.9133 50.9819 40.389 50.9819 39.7415V27.936C50.9819 27.2891 51.5068 26.7642 52.1538 26.7642C52.8008 26.7642 53.3257 27.2891 53.3257 27.936V39.7415C53.3257 40.389 52.8008 40.9133 52.1538 40.9133Z"
          fill="#252D4C"
        />
        <path
          d="M58.3506 40.9133C57.7036 40.9133 57.1787 40.389 57.1787 39.7415V27.936C57.1787 27.2891 57.7036 26.7642 58.3506 26.7642C58.9976 26.7642 59.5225 27.2891 59.5225 27.936V39.7415C59.5225 40.389 58.9976 40.9133 58.3506 40.9133Z"
          fill="#252D4C"
        />
        <path
          d="M61.4487 40.9133C60.8018 40.9133 60.2769 40.389 60.2769 39.7415V27.936C60.2769 27.2891 60.8018 26.7642 61.4487 26.7642C62.0963 26.7642 62.6206 27.2891 62.6206 27.936V39.7415C62.6206 40.389 62.0963 40.9133 61.4487 40.9133Z"
          fill="#252D4C"
        />
        <path
          d="M67.6458 40.9133C66.9988 40.9133 66.4739 40.389 66.4739 39.7415V27.936C66.4739 27.2891 66.9988 26.7642 67.6458 26.7642C68.2933 26.7642 68.8176 27.2891 68.8176 27.936V39.7415C68.8176 40.389 68.2933 40.9133 67.6458 40.9133Z"
          fill="#252D4C"
        />
        <path
          d="M70.7446 40.9133C70.097 40.9133 69.5728 40.389 69.5728 39.7415V27.936C69.5728 27.2891 70.097 26.7642 70.7446 26.7642C71.3916 26.7642 71.9165 27.2891 71.9165 27.936V39.7415C71.9165 40.389 71.3916 40.9133 70.7446 40.9133Z"
          fill="#252D4C"
        />
        <path
          d="M75.4247 28.0885C74.7771 28.0885 74.2528 27.5636 74.2528 26.9166V25.3975H72.7336C72.0861 25.3975 71.5618 24.8726 71.5618 24.2256C71.5618 23.578 72.0861 23.0537 72.7336 23.0537H75.4247C76.0717 23.0537 76.5966 23.578 76.5966 24.2256V26.9166C76.5966 27.5636 76.0717 28.0885 75.4247 28.0885Z"
          fill="#252D4C"
        />
        <path
          d="M75.4247 44.6241H72.7336C72.0861 44.6241 71.5618 44.0992 71.5618 43.4523C71.5618 42.8053 72.0861 42.2804 72.7336 42.2804H74.2528V40.7612C74.2528 40.1143 74.7771 39.5894 75.4247 39.5894C76.0717 39.5894 76.5966 40.1143 76.5966 40.7612V43.4523C76.5966 44.0999 76.0717 44.6241 75.4247 44.6241Z"
          fill="#252D4C"
        />
        <path
          d="M47.4736 28.0885C46.8267 28.0885 46.3018 27.5636 46.3018 26.9166V24.2256C46.3018 23.578 46.8267 23.0537 47.4736 23.0537H50.1647C50.8116 23.0537 51.3365 23.578 51.3365 24.2256C51.3365 24.8726 50.8116 25.3975 50.1647 25.3975H48.6455V26.9166C48.6455 27.5636 48.1212 28.0885 47.4736 28.0885Z"
          fill="#252D4C"
        />
        <path
          d="M50.1647 44.6241H47.4736C46.8267 44.6241 46.3018 44.0992 46.3018 43.4523V40.7612C46.3018 40.1143 46.8267 39.5894 47.4736 39.5894C48.1212 39.5894 48.6455 40.1143 48.6455 40.7612V42.2804H50.1647C50.8116 42.2804 51.3365 42.8053 51.3365 43.4523C51.3365 44.0992 50.8116 44.6241 50.1647 44.6241Z"
          fill="#252D4C"
        />
      </svg>
    </>
  )
}
