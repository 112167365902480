import React from 'react'

interface SvgProp {
  className?: string
}

export const SVGUploadImage: React.FC<SvgProp> = ({ ...props }) => {
  return (
    <>
      <svg
        className={`${props.className}`}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.2222 4H5.77778C4.79594 4 4 4.79594 4 5.77778V18.2222C4 19.2041 4.79594 20 5.77778 20H18.2222C19.2041 20 20 19.2041 20 18.2222V5.77778C20 4.79594 19.2041 4 18.2222 4Z"
          stroke="#657381"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.88888 10.2223C9.62526 10.2223 10.2222 9.62538 10.2222 8.889C10.2222 8.15262 9.62526 7.55566 8.88888 7.55566C8.1525 7.55566 7.55554 8.15262 7.55554 8.889C7.55554 9.62538 8.1525 10.2223 8.88888 10.2223Z"
          stroke="#657381"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 14.6666L15.5555 10.2222L5.77777 19.9999"
          stroke="#657381"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
