interface SvgProp {
  className?: string
}

export const SVGLogo: React.FC<SvgProp> = ({ className = '' }) => {
  return (
    <>
      <svg
        className={className}
        width="140"
        height="155"
        viewBox="0 0 140 155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip2)">
          <path
            d="M95.1735 126.108H43.4088C19.4117 126.108 0 106.734 0 82.7836V43.3241C0 19.3738 19.4117 0 43.4088 0H95.1735C119.119 0 138.582 19.3738 138.582 43.3241V82.7836C138.582 106.734 119.17 126.108 95.1735 126.108Z"
            fill="#33A9FF"
          />
          <path
            d="M64.7061 108.818L55.5352 146.854C54.2612 152.244 60.1716 156.465 64.8587 153.464L118.203 119.497L64.7061 108.818Z"
            fill="#33A9FF"
          />
          <path
            d="M50.6939 49.8328C54.4928 49.8328 57.5723 46.7591 57.5723 42.9678C57.5723 39.1765 54.4928 36.103 50.6939 36.103C46.8954 36.103 43.8159 39.1765 43.8159 42.9678C43.8159 46.7591 46.8954 49.8328 50.6939 49.8328Z"
            fill="white"
          />
          <path d="M102.917 38.1372H73.8765V47.2903H102.917V38.1372Z" fill="white" />
          <path
            d="M103.427 64.8335C103.427 83.4954 88.2442 98.6487 69.546 98.6487C50.8473 98.6487 35.6646 83.4954 35.6646 64.8335C35.6646 64.7317 35.6646 64.681 35.6646 64.5791H44.8354C44.8354 64.681 44.8354 64.7317 44.8354 64.8335C44.8354 78.4101 55.9423 89.4956 69.546 89.4956C83.1491 89.4956 94.256 78.4101 94.256 64.8335C94.256 64.7317 94.256 64.681 94.256 64.5791H103.427C103.427 64.681 103.427 64.7317 103.427 64.8335Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip2">
            <rect width="140" height="154.482" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
