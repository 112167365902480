interface SvgProp {
  className?: string
  onClick?: () => void
  width?: number
  height?: number
  color?: string
}

export const SVGArrowRight: React.FC<SvgProp> = ({
  className = '',
  onClick,
  width = 16,
  height = 16,
  color = 'white'
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="12"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.47149 2.86177C8.21114 2.60142 7.78903 2.60142 7.52868 2.86177C7.26833 3.12212 7.26833 3.54423 7.52868 3.80458L11.0573 7.33317H3.33341C2.96522 7.33317 2.66675 7.63165 2.66675 7.99984C2.66675 8.36803 2.96522 8.6665 3.33341 8.6665H11.0573L7.52868 12.1951C7.26833 12.4554 7.26833 12.8776 7.52868 13.1379C7.78903 13.3983 8.21114 13.3983 8.47149 13.1379L13.1382 8.47124C13.3985 8.21089 13.3985 7.78878 13.1382 7.52843L8.47149 2.86177Z"
          fill="#222427"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="24" height="24" fill={color} />
      </g>
    </svg>
  )
}
